import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import BackButton from '../components/ui/BackButton';
import Button from '../components/ui/Button';
import InputDate from '../components/ui/InputDate';
import Input from '../components/ui/Input';
import InputGroup from '../components/ui/InputGroup';
import InputGroupRemovable from '../components/ui/InputGroupRemovable';
import Label from '../components/ui/Label';
import StepperLayout from '../components/layouts/StepperLayout';
import UniversalInputGenerator from '../interfaces/UniversalInputGenerator';
import {
  getEndDate,
  getPeakOffPeakUsage,
  getStartDate,
  setBlockRatesUsage,
  setEndDate,
  setPeakOffPeakUsage,
  setSingleRateUsage,
  setStartDate,
} from '../utils/localStorage/localStorageFunctions';
import { PeakOffPeakUsageLsType } from '../utils/localStorage/localStorageTypes';
import { navigateAndSetActiveStep } from '../utils/utils';
import { StepUrl } from '../utils/enums';
import usePreloadPageData from '../hooks/usePreloadPageData';
import SEOLayout from '../components/layouts/SEOLayout';

const UsageSchema = Yup.number()
  .typeError('Usage must be a number')
  .min(0.01, 'Usage must be more than 0')
  .max(10_000, 'Please check the usage amount');

const PeakOffPeakSchema = Yup.object().shape(
  {
    peakUsage: UsageSchema.required('Please, specify the usage amount'),
    offPeakUsage: UsageSchema.required('Please, specify the usage amount'),
    startDate: Yup.date()
      .typeError('Please, specify the start date')
      .when('endDate', (endDate: any, schema: any) => {
        return (
          endDate && schema.max(endDate, 'Start date needs to before end date')
        );
      })
      .required('Please, specify the start date'),
    endDate: Yup.date()
      .typeError('Please, specify the end date')
      .when(
        'startDate',
        (startDate: any, schema: any) =>
          startDate &&
          schema.min(startDate, 'End date needs to be after start date')
      )
      .required('Please, specify the end date'),
    shoulderUsage1: UsageSchema,
    shoulderUsage2: UsageSchema,
    shoulderUsage3: UsageSchema,
  },
  [['startDate', 'endDate']]
);

const PeakOffPeakTariff = () => {
  const inputsLimit = 3;

  const [inputs, setInputs] = useState<UniversalInputGenerator[]>(() => {
    const inputsArray = [];

    const { shoulderUsage1, shoulderUsage2, shoulderUsage3 } =
      getPeakOffPeakUsage() || {};

    if (shoulderUsage1) {
      inputsArray.push({
        label: `Shoulder 1 usage`,
        id: `shoulder-usage-1`,
        name: `shoulderUsage1`,
        index: 1,
        removable: true,
      });
    }
    if (shoulderUsage2) {
      inputsArray.push({
        label: `Shoulder 2 usage`,
        id: `shoulder-usage-2`,
        name: `shoulderUsage2`,
        index: 2,
        removable: true,
      });
    }
    if (shoulderUsage3) {
      inputsArray.push({
        label: `Shoulder 3 usage`,
        id: `shoulder-usage-3`,
        name: `shoulderUsage3`,
        index: 3,
        removable: true,
      });
    }

    return inputsArray;
  });

  const addShoulderUsageInput = () => {
    setInputs((prevState) => {
      const lastElement = prevState?.[prevState.length - 1];
      const newElementIndex = (lastElement?.index ?? 0) + 1;

      const newInput = {
        label: `Shoulder ${newElementIndex} usage`,
        id: `shoulder-usage-${newElementIndex}`,
        name: `shoulderUsage${newElementIndex}`,
        index: newElementIndex,
        removable: true,
      };

      return [...prevState, newInput];
    });
  };

  const removeInputByName = (inputName: string) => {
    setInputs((prevState) =>
      prevState.filter((input) => input.name !== inputName)
    );
  };

  const isBelowInputLimit =
    (inputs?.[inputs.length - 1]?.index ?? 0) < inputsLimit;

  const [initialFormValues] = useState(() => {
    const startDateLS = getStartDate();
    const endDateLS = getEndDate();
    const {
      peakUsage,
      offPeakUsage,
      shoulderUsage1,
      shoulderUsage2,
      shoulderUsage3,
    } = getPeakOffPeakUsage() || {};

    return {
      startDate: startDateLS || '',
      endDate: endDateLS || '',
      peakUsage: peakUsage?.toString() || '',
      offPeakUsage: offPeakUsage?.toString() || '',
      shoulderUsage1: shoulderUsage1?.toString() || '',
      shoulderUsage2: shoulderUsage2?.toString() || '',
      shoulderUsage3: shoulderUsage3?.toString() || '',
    };
  });

  const handleNext = (values: typeof initialFormValues) => {
    const {
      offPeakUsage,
      peakUsage,
      startDate,
      endDate,
      shoulderUsage1,
      shoulderUsage2,
      shoulderUsage3,
    } = values;
    setStartDate(startDate);
    setEndDate(endDate);

    const peakOffPeakUsageData: PeakOffPeakUsageLsType = {
      peakUsage: parseInt(peakUsage, 10),
      offPeakUsage: parseInt(offPeakUsage, 10),
    };

    if (shoulderUsage1) {
      peakOffPeakUsageData.shoulderUsage1 = parseInt(shoulderUsage1, 10);
    }
    if (shoulderUsage2) {
      peakOffPeakUsageData.shoulderUsage2 = parseInt(shoulderUsage2, 10);
    }
    if (shoulderUsage3) {
      peakOffPeakUsageData.shoulderUsage3 = parseInt(shoulderUsage3, 10);
    }

    setPeakOffPeakUsage(peakOffPeakUsageData);
    setBlockRatesUsage(null);
    setSingleRateUsage(null);

    navigateAndSetActiveStep(StepUrl.URL_CONTROLLED_LOAD);
  };

  const handleBack = () => {
    navigateAndSetActiveStep(StepUrl.URL_ABOUT_YOUR_BILL);
  };

  usePreloadPageData(StepUrl.URL_CONTROLLED_LOAD, StepUrl.URL_ABOUT_YOUR_BILL);

  return (
    <SEOLayout>
      <StepperLayout
        currentStage={2}
        currentStep={6}
        pageUrl={StepUrl.URL_PEAK_OFF_PEAK_TARIFF}
      >
        <div className="group my-3">
          <Formik
            initialValues={initialFormValues}
            onSubmit={(values) => {
              handleNext(values);
            }}
            validationSchema={PeakOffPeakSchema}
          >
            {(props) => (
              <Form>
                <h2 className="my-2">Peak/off peak tariff</h2>
                <p className="sub-heading">
                  This information can be found on your bill.
                </p>
                <InputGroup className="my-2">
                  <Label htmlFor="input-start-date">Start date</Label>
                  <InputDate id="input-start-date" name="startDate" />
                </InputGroup>
                <InputGroup className="my-2">
                  <Label htmlFor="input-end-date">End date</Label>
                  <InputDate id="input-end-date" name="endDate" />
                </InputGroup>
                <InputGroup className="my-2">
                  <Label htmlFor="peak-usage">Peak usage</Label>
                  <Input
                    id="peak-usage"
                    name="peakUsage"
                    type="number"
                    unit="kWh"
                    controls
                    min={0.01}
                    step={0.01}
                  />
                </InputGroup>
                <InputGroup className="my-2">
                  <Label htmlFor="off-peak-usage">Off peak usage</Label>
                  <Input
                    id="off-peak-usage"
                    name="offPeakUsage"
                    type="number"
                    unit="kWh"
                    controls
                    min={0.01}
                    step={0.01}
                  />
                </InputGroup>
                {inputs.map((input, index) => (
                  <InputGroupRemovable
                    isRemovable={
                      index + 1 === inputs.length && !!input?.removable
                    }
                    className="my-2"
                    key={input.name}
                    label={<Label htmlFor={input.id}>{input.label}</Label>}
                    input={
                      <Input
                        id={input.id}
                        name={input.name}
                        type="number"
                        unit="kWh"
                        controls
                        min={0.01}
                        step={0.01}
                      />
                    }
                    onRemove={() => {
                      removeInputByName(input.name);
                      props.setFieldValue(input.name, '');
                    }}
                  />
                ))}
                {isBelowInputLimit && (
                  <Button
                    className="my-2"
                    type="button"
                    variant="link"
                    onClick={() => {
                      addShoulderUsageInput();
                    }}
                  >
                    Add shoulder usage
                  </Button>
                )}
                <Button
                  className="mt-5"
                  type="submit"
                  disabled={
                    props.values.startDate === '' ||
                    props.values.endDate === '' ||
                    props.values.peakUsage === '' ||
                    props.values.offPeakUsage === ''
                  }
                >
                  Continue
                </Button>
              </Form>
            )}
          </Formik>
        </div>
        <BackButton text="Back" onClick={handleBack} />
      </StepperLayout>
    </SEOLayout>
  );
};

export default PeakOffPeakTariff;
